g #circle_07 {
    animation: rotate 5s infinite;
}
g #circle_02 {
    animation: rotate 5s infinite;
}

#circle_11 {
    animation: rotate 10s  2s forward;
}
@keyframes rotate {
    from {
        transform: rotateX(0deg);
    }
    to {
        transform: rotateX(360deg);
    }
    }