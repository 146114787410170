.App {
  text-align: center;
  height: fit-content;
}

.App-header {
  background-size: cover;
  background-color: #282c34;

  min-height: 12vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-header::before {
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
  content: '';
  background: hsla(0, 0%, 100%, .5);
  opacity: 0.6;
  z-index: -1;
}

#documents div{
  margin: 1rem 0;
  text-align: center;
}

#documents p {
  width: 70%;
  margin: 1rem 2.5rem;
}

#documents img {
  height: 10rem;
}

#documents a {
  color: #FFF;

}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

.contact-data {
  color: #000;
}

#contact-data:hover {
  opacity: 1;
}

#contact-data {
  background: rgba(21, 223, 28, 0.66);

  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: #FFF;
  padding: 10vh 1vw;
  font-size: larger;
  font-variant: small-caps;
  box-shadow: #000 0px 5px 10px;
  background-repeat: no-repeat;
  background-size: 90%;
  border: 0px solid black;
  width: 17vw;
  height: 17vw;
  border-radius:90%;
  transform: scale(0.8);
  z-index: 3;
}
#contact-data h1:nth-child(1){
  -webkit-text-stroke: 0px #FFF;
  color: #000;
  font-size: 3em;
  margin-top: 2vh;
}



footer {
  
  background: linear-gradient(to bottom, #dfe9f3 0%, white 100%);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin-top: 250px;
  min-height: 400px;
}

header {
  background: hsla(0, 0%, 70%, .3);
  height: 100vh;
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
  border-top: 1px #000 solid;  
  border-bottom: 1px #000 solid;
}

header h2, footer h2 {
  -webkit-text-stroke: 1px black;
  font-variant: small-caps;
}
header p {
  -webkit-text-stroke: 0.1px black;
}

.hjem div , .installation div{
  color: #FFF;
  height: 70vh;
  margin: 3vh 0;
}

.installation div:not(#pic, #installation-button) {
  height: 50rem;
}

#installation-button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 50% 33%;
  background-color: #000;
  border: #ffba00 solid 2px;
  border-radius: 5px;
  width: 33%;
  height: 10%;
  cursor: pointer;
}

#installation-button p{
  margin: 2%;
  padding: 1%; 
  font-size: 150%;
  font-style: italic;
}

nav {
  position: sticky;
  background: hsla(0, 0%, 100%, .1);
  top: 0;
  font-variant: small-caps;
  z-index: 13;
  font-size: x-large;
}

nav img {
  height: 30px;
  margin: 0 10px 0 10px;

}

nav #logotype {

}


.App-header {
  background-color: #282c34;
  opacity: 0.7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.content {
  position: relative;
  display: flex;
  margin: 10vh 0vw;
  padding: 0;
  min-width: 70vw;
  min-height: 1vh;
  align-items: center;
  animation: pageIn 1s forwards;
  
}

.navigation-dots, .dedicated-section{
  min-height: 90vh;
  margin: 0% 0;
  padding: 5% 0;
  vertical-align: middle;
  text-align: center;
}



.dedicated-section div:last-child {
  z-index: 3;

}

.dedicated-section div {
  min-height: 3vh;
  text-align: center;
}

.dedicated-section p{
  color: #FFF;
  text-align: center;
}

.navigation-dots p{
  margin: 0;
  padding: 0;
  font-size: 500%;
    text-align: center;
}

.navigation-dots p:hover {
  color: #ffba00;
  cursor: pointer;
}

#kontakt-panel {
  color: #FFF;
  margin-top: 10%;
}

.kontakt img{
}

.kontakt h3 {
  margin-top: 20px;
}

.kontakt p {
  font-size: x-large;
}

.kontakt p:first-child {
  text-align: center;
  font-family: 'DM Serif Text';
}

.kontakt p{
  text-align: left;
  font-family: 'Quattrocento';
  font-weight: 600;
}

.brief {
  text-decoration: underline #ffba00 1px;

}

.header-link {
  cursor: pointer;
}

.kontakt a {
  color: #000;
  font-style:none;
}

.kontakt a:hover {
  color: #000;
  font-style:none;
}

.title {
  top: 0;
  background-color: #FFF;
  display: block;
  width: 100%;
  height: fit-content;
}

.kontakt {
  color: #000;
  position: relative;
  min-width: 70vw;
  min-height: fit-content;
  background: linear-gradient(to bottom, #dfe9f3 0%, white 100%);
  animation: pageIn 1s forwards;
}

.watermark { 
    z-index: -1;
    opacity: 0.3;
    position: fixed;
    top: 5vh;
    left: 50vw;
    width: 100vh;
    height: 100vh; 
    background-repeat: no-repeat;
}
.about-us{
  color: #000;
  animation: pageIn 1s forwards;
}

.about-us h1, .hjem h1, .installation h1 {
  font-family: 'DM Serif Text';
  font-variant: small-caps;
  font-size: 3rem;
  font-weight: 600;
  margin: 10vh 0;
}

.about-us h2 {
  font-family: 'Quattrocento';
  font-size: 2rem;
  font-weight: 600;
  margin: 3vh 0;
}

.about-us h3 {
  font-family: 'Quattrocento';
  font-size: 2.5rem;
  font-weight: 900;
}

.about-us a, .about-us a:hover {
  color: #000;
  font-weight: 900;
}



.about-us p, .hjem p, .installation p {
  margin: 4vh 0;
}

.about-us p:nth-child(2), .hjem p:nth-child(3), .installation p:nth-child(3){
  font-family: 'Dancing Script';
  font-size: xx-large;
  font-weight: 600;
}

.about-us p:nth-child(3), .hjem p:nth-child(2), .installation p:nth-child(2){
  font-family: 'DM Serif Text';
  font-size: xx-large;
  font-weight: 600;
}

.about-us p:nth-child(4), .hjem p:nth-child(4), .installation p:nth-child(4){
  font-family: 'Quattrocento';
  font-size: xx-large;
  font-weight: 600;
}

.about-us-logo {
  height: 40vmin;
  pointer-events: none;
  opacity: 1;
}
.about-us-logotype {
  height: 40vmin;
  pointer-events: none;
  opacity: 1;
  margin: 30px 0;
}


.picture {
  position: relative;
  border: 0px solid #000; 
}

.picture-background {
  width: 100%;
  height: 100%;  
  filter: url(#filter);
  box-shadow: -400px 400px 60px 100px #fff;
  position: absolute;
  top: -520px;
  left: -420px;
  opacity: 0.3;
  z-index: -1;
  border-radius: 100%;
}
.picture-second-background {
  width: 120%;
  height: 100%;  
  box-shadow: inset 20px 20px 200px rgba(255, 255, 255, .9);
  filter: blur(2px);
  position: absolute;
  top: -30px;
  left: -150px;
  opacity: 0.8;
  z-index: -1;
  border-radius: 2%;
}

.picture div {
  height: 90vh;
}

.picture img {
  height: 100%;
  box-shadow: 3px 15px 50px rgba(45, 43, 43, 0.9);


}
.description {
  opacity: 0;
  text-align: justify;
  animation: pageIn 0.5s forwards;
  animation-delay: 0.3s;
  min-height: 90vh;
  padding: 0px 50px;
  vertical-align: middle;
  color: #FFF;
  position: relative;
}

.description p {
  width: 80%;
}

.description h2 {
  margin: 10vh 0;
}

.description h3 {
  margin: 3vh 0;
}

.description h3:first-child {
  margin: 10vh 0 0 0;
}

.description div:first-child h1 {
  font-variant: small-caps;
  font-weight: 600;
  margin-bottom: 20px;
  border-bottom: #ffba00 3px solid;
  width: 100%;
  font-size: 140%;
}

.description-background {
  width: 100%;
  height: 100%;  
  box-shadow: inset 20px 20px 200px rgba(255, 255, 255, .9);
  filter: blur(2px);
  position: absolute;
  top: -30px;
  left: -5px;
  opacity: 1;
  z-index: -1;
  border-radius: 2%;
}

.description-box-navigation-gallery {
  display: none;
}

.footer {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
  padding: 10px;
  margin-top: 50vh;
}

footer p {
  color: #000;
  font-size: x-large;
  font-variant: small-caps;
  font-weight: 600;
}


.footer-contact {

  margin: 40px 15px;
}

.footer-contact p {
  font-weight: 600;
  font-size: x-large;
}
.social-ico a:active{
	color: #FFF;
	transform: scale(0.8);
	text-decoration: none;
}

.social-ico p{
  display: inline;
  margin: 0 15px;
}


.social-ico img {
	height: 150px;
	width: 150px;
	margin: 40px 0;
}

.gallery-insta {
  filter: grayscale(1);  
  vertical-align: top;
  width: 15vw;
  height: 15vw;
  margin: 15px;
  display: inline-grid;
}

.gallery-insta:hover {
  filter: none;
}


.nav-logo img{
	min-height: 100px;
	min-width: 100px;
  margin: 0 7px; 
}

.footer-logo img{
  max-width: 500px;
  max-height: 200px;
	min-height: 100px;
	min-width: 100px;
  margin: 0 4px;
}

.footer-ajs-logo img{
  max-width: 200px;
  max-height: 200px;
	min-height: 100px;
	min-width: 100px;
  margin: 0 4px;
}

.footer-logo p{  
  font-variant: small-caps;
}

div .thumbnail
{
  min-height: 140px;
	align-content: right;
  margin: 0 0;
}

.miniature {
  width: 15vw;
  height: 15vw;
  margin: 15px;
  display: inline-grid;
  border-color: #000;  
  border-radius: 100%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  animation: rotateIn 1s 0.2s forwards;
}

.miniature:hover {
  margin: 15px;
  display: inline-grid;
  border-color: #000;
  cursor: pointer;
  animation: rotateIn 0.3s 0 backwards;
  background-size: 300%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.9);
}

.miniature:active {
  margin: 15px;
  display: inline-grid;
  border-color: #000;
  opacity: 1;
  transition: 1s;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
}

@-webkit-keyframes rotateIn{
  from {
    border-radius: 100%;
  }
  to { 
    border-radius: 0%;
  }
}

@keyframes rotateIn{
  from { 
    border-radius: 100%;
   }
  to { 
    border-radius: 0%;
  }
}


.gallery {
  position: relative;
  margin: 10vh 0 7vh 0;
  padding: 2vw;
  min-width: 70vw;
  min-height: 40vh;
  opacity: 0;
  animation-name: pageIn;
  animation-duration: 1s;
  animation-delay: 0.2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

#norge {
  width: 40vw;
}

@-webkit-keyframes pageIn{
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes pageIn{
    from { opacity: 0; }
    to   { opacity: 1; }
}

@media only screen and (max-width: 800px) {
.navigation-dots p{
  text-align: center;
  display: inline;
}

.hjem div:not(#pic), .installation div:not(#pic) {
  height: 60vh;
}
.hjem #pic, .installation #pic {
  height: 60vh;
}

#installation-button {
  margin-top: 80%;
  margin-bottom: 0%;
  max-height: 20%;
}

#navigation-dots {
  position: sticky;
  top: 15vh;
  z-index: 4;
  opacity: 0;
  text-align: justify;
  animation: pageIn 0.5s forwards;
  animation-delay: 0.3s;
  vertical-align: middle;
  color: #FFF;
}

#navigation-dots h1{
  font-variant: small-caps;
  font-weight: 600;
  border-bottom: #ffba00 3px solid;
  width: 100%;
  font-size: 140%;
}


.navigation-dots p {
  margin: 0 2.5vh; 
   height: 2vh;
   line-height: 1rem;
}
.navigation-dots {

  min-height: 13vh;
}

.dedicated-section {
  height: 15vh;
  min-height: 15vh;
  width: 90%;
  margin: 0 5vw;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 15%;
}

.description-background {
  width: 100%;
  height: 100%;  
  box-shadow: inset 20px 20px 200px rgba(255, 255, 255, .9);
  filter: blur(2px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  opacity: 0.8;
  z-index: -1;
  border-radius: 2%;
}

.description-box-navigation-gallery p{
  display: inline;
}
  .description {
    margin: 5vh 1vw 5vh 1vw;
    padding: 3%;
    text-align: justify;
    min-height: 80vh;

    font-size: 100%;
    border: none;
  }
  .description p, .about-us p, .about-us p:nth-child(3), .about-us p:nth-child(4){
    font-size: 110%;
    width: 99%;
  }

  .description h3 {
    font-size: 1.3rem;
  }
  .about-us h2{
    margin: 3vh 0 1vh 0
  }
  

  .about-us h1{
    margin: 10vh 0 2vh 0
  }
  .footer { 
    margin-top: 0;
  }
  .footer-logo img{
    max-width: 90vw;
    max-height: auto;
    min-height: auto;
    min-width: 50vw;
    margin: 0 4px;
  }

  header {
    height: 300px;
  }

  .gallery {
    margin: 0 0 7vh 0;
  }

  .content {
    margin: 5vh 5vw ;
    min-width: 80vw;
  }

  .footer, .preface {
    display: inline-block;
  }

  .preface h3 {
    font-size: larger;

  }
  .miniature {
    width: 30vw;
    height: 30vw;
  }  
  .picture-big {
    display: none;

   }
   .watermark {
    top: 35vh;
    left: 20vw;
    height: 300px;
    width: 300px;
   }
   .gallery-insta p {
    visibility: hidden;
   }
   .nav-logo img{
    height: auto;
    width: 70vw;
  }

}






